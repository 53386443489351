import React from 'react'
import Layout from '../components/Layout'
import Carbon from '../../src/images/Carbon2.jpg'
import './tomcat-difference.scss'
import PaddedLayout from '../components/PaddedLayout'
import { Link } from 'gatsby'
export default function TomcatDifference() {
    return (
        <Layout>
                <div className="difference-header"> 
                    <h4>The Tomcat <span className="blue">Difference</span></h4>
                </div>
            <PaddedLayout>

                <div className="difference-articles">
                    <div><img src={Carbon} alt="" /></div>
                    <div>
                        <h3>Tomcat Engines</h3>
                        <span className="subtitle">EXPERIENCE TRUE POWER TO PERFORMANCE</span>
                        <p>Ipsum voluptate et exercitation officia et non non dolor. Excepteur est labore veniam ipsum exercitation adipisicing. Exercitation laborum nisi duis anim laboris sint labore aliquip qui tempor incididunt. Qui est eu laboris voluptate aute aute quis elit nostrud fugiat. Cupidatat eu veniam nostrud mollit. Reprehenderit velit labore exercitation dolore ad nisi sunt amet reprehenderit commodo velit.</p>
                        <Link to="">Tomcat Engines</Link>
                    </div>
                </div>
            </PaddedLayout>
        </Layout>
    )
}
